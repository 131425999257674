<template>
  <div>
    <b-row>
      <b-col>
        <validation-observer ref="reason-for-coaching-modal-observer">
          <validation-provider
            v-slot="{ errors }"
            name="reasonForCoachingRequest"
            rules="required|min:3|max:1400"
            mode="lazy"
          >
            <b-form-group>
              <label for="reason-for-coaching-request">
                {{ $t('For which professional issue do you want coaching on?') }}
              </label>
              <b-form-textarea
                id="reason-for-coaching-request"
                v-model="reasonForCoachingRequest"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormTextarea,
  BLink,
  BRow
} from "bootstrap-vue";
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormTextarea,
    BLink,
    BRow,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    mentee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reasonForCoachingRequest: null
    }
  },
  created() {
    if(this.mentee) {
      this.reasonForCoachingRequest = this.mentee.reason_for_coaching
    }
  },
  methods: {
    isValid() {
      return this.$refs['reason-for-coaching-modal-observer'].validate().then(success => success)
    },
    collectData() {
      return {
        reason_for_coaching: this.reasonForCoachingRequest 
      }
    }
  }
};
</script>

<style scoped>

</style>
