var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"signInDetailsObserver"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-sign-in-email"}},[_vm._v(" "+_vm._s(_vm.$t('SignInEmail'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"signInEmail","rules":"required|email|min:3|max:255","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"i-sign-in-email","disabled":"","type":"email"},model:{value:(_vm.signInEmail),callback:function ($$v) {_vm.signInEmail=$$v},expression:"signInEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-old-password"}},[_vm._v(" "+_vm._s(_vm.$t('Old password'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"oldPassword","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"i-old-password","type":_vm.oldPasswordFieldType},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.toggleOldPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-new-password"}},[_vm._v(" "+_vm._s(_vm.$t('New password'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"newPassword","rules":"required|password","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"i-new-password","type":_vm.newPasswordFieldType},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.toggleNewPasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"i-new-password-confirmation"}},[_vm._v(" "+_vm._s(_vm.$t('New password confirmation'))+" "),_c('required-field')],1),_c('validation-provider',{attrs:{"name":"newPasswordConfirmation","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"i-new-password-confirmation","type":_vm.newPasswordConfirmationFieldType},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.toggleNewPasswordConfirmationVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }