<template>
  <div>
    <testimonial-settings class="testimonial-settings mb-2 pb-2" @save-changes="saveChanges" />
    <b-card-title>
      {{ $t('Testimonials') }}
    </b-card-title>
    <testimonials-list :testimonials="testimonials" />
  </div>
</template>

<script>
import {
  BCardTitle
} from "bootstrap-vue";
import TestimonialsList from '@mentoring-platform/views/components/blocks/TestimonialsList.vue'
import TestimonialSettings from '@mentoring-platform/views/components/blocks/MyAccount/TestimonialSettings.vue'
import { useTestimonial } from '@mentoring-platform/composables'

export default {
  components: {
    BCardTitle,
    TestimonialsList,
    TestimonialSettings
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  setup() {
    const { testimonials, getMyTestimonials } = useTestimonial()
    getMyTestimonials()

    return {
      testimonials
    }
  },
  methods: {
    saveChanges(data) {
      const payload = data;
      data.mentor_id = this.mentor.id
      this.$store.dispatch('user/mentorEdit', payload)
    }
  }
};
</script>

<style lang="scss" scoped>
.testimonial-settings {
  border-bottom: 1px solid lightgrey;
}

.testimonial-items {

  .testimonial-item:not(:last-child) {
    margin-bottom: 1.5rem;
  }

}
</style>
