<template>
  <div>
    <page-header class="mb-3" :title="$t('My account')" :message="$t('View and edit your account details')" />
    <b-tabs
      v-if="userData"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab v-if="isMentor" active>
        <template #title>
          <my-account-tab-title :text="$t('Mentoring languages')" icon="FlagIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('mentoring-languages')">
          <mentoring-languages ref="mentoring-languages" :mentor="userData.mentor" show-modal />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="!isAdmin">
        <template #title>
          <my-account-tab-title :text="$t('AccountDetails')" icon="UserIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('personal-details')">
          <personal-details v-if="isMentor" ref="personal-details" :mentor="userData.mentor" :email-pre-check="false" showMentorFields />
          <personal-details v-else-if="isMentee" ref="personal-details" :mentee="userData.mentee" :email-pre-check="false" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="!isAdmin">
        <template #title>
          <my-account-tab-title :text="$t('ProfessionalDetails')" icon="BriefcaseIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('professional-details')">
          <professional-details v-if="isMentor" ref="professional-details" :mentor="userData.mentor" showMentorFields />
          <professional-details v-else-if="isMentee" ref="professional-details" :mentee="userData.mentee" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="isMentor">
        <template #title>
          <my-account-tab-title :text="$t('MentorRole')" icon="InfoIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('mentor-role')">
          <mentor-role ref="mentor-role" :mentor="userData.mentor" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="isMentor">
        <template #title>
          <my-account-tab-title :text="$t('MentorProfile')" icon="UserCheckIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('mentor-profile')">
          <mentor-profile ref="mentor-profile" :mentor="userData.mentor" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="isMentee && userData.mentee.reason_for_coaching && userData.mentee.reason_for_coaching.length > 0">
        <template #title>
          <my-account-tab-title :text="$t('Coaching details')" icon="UsersIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('coaching-details')">
          <coaching-details ref="coaching-details" :mentee="userData.mentee" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="isMentee">
        <template #title>
          <my-account-tab-title :text="$t('Mentoring details')" icon="UsersIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('mentoring-details')">
          <mentoring-details ref="mentoring-details" :mentee="userData.mentee" show-hint />
        </my-account-tab-content>
      </b-tab>

      <b-tab>
        <template #title>
          <my-account-tab-title :text="$t('SignInDetails')" icon="KeyIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('sign-in-details')">
          <sign-in-details ref="sign-in-details" :user="userData" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="isMentor">
        <template #title>
          <my-account-tab-title :text="$t('Testimonials')" icon="StarIcon" />
        </template>

        <my-account-tab-content no-footer >
          <testimonials ref="testimonials" :mentor="userData.mentor" />
        </my-account-tab-content>
      </b-tab>

      <b-tab v-if="isMentor">
        <template #title>
          <my-account-tab-title :text="$t('Status')" icon="ToggleLeftIcon" />
        </template>

        <my-account-tab-content @save-changes="saveChanges('mentoring-status')">
          <mentoring-status ref="mentoring-status" :mentor="userData.mentor" />
        </my-account-tab-content>
      </b-tab>
      <required-field-explanation />
    </b-tabs>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BTabs,
  BTab
} from "bootstrap-vue";
import MentorProfile from "@mentoring-platform/views/components/blocks/MentorProfile.vue";
import MentorRole from "@mentoring-platform/views/components/blocks/MentorRole.vue";
import PersonalDetails from "@mentoring-platform/views/components/blocks/PersonalDetails.vue";
import ProfessionalDetails from "@mentoring-platform/views/components/blocks/ProfessionalDetails.vue";
import RequiredFieldExplanation from "@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue";
import SignInDetails from "@mentoring-platform/views/components/blocks/MyAccount/SignInDetails.vue";
import CoachingDetails from "@mentoring-platform/views/components/blocks/CoachingDetails.vue"
import MentoringDetails from "@mentoring-platform/views/components/blocks/MentoringDetails.vue"
import MentoringStatus from "@mentoring-platform/views/components/blocks/MyAccount/MentoringStatus.vue"
import MyAccountTabContent from "@mentoring-platform/views/components/blocks/MyAccount/MyAccountTabContent.vue"
import MyAccountTabTitle from "@mentoring-platform/views/components/blocks/MyAccount/MyAccountTabTitle.vue"
import Testimonials from "@mentoring-platform/views/components/blocks/MyAccount/Testimonials.vue"
import PageHeader from "@mentoring-platform/views/components/blocks/PageHeader.vue"
import MentoringLanguages from '@mentoring-platform/views/components/blocks/MentoringLanguages.vue'

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BTabs,
    BTab,
    CoachingDetails,
    MentoringDetails,
    MentoringLanguages,
    MentoringStatus,
    MentorProfile,
    MentorRole,
    MyAccountTabContent,
    MyAccountTabTitle,
    PageHeader,
    PersonalDetails,
    ProfessionalDetails,
    RequiredFieldExplanation,
    SignInDetails,
    SignInDetails,
    Testimonials
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/getUserData']
    },
    isMentor() {
      return this.$can('read', 'mentor')
    },
    isMentee() {
      return this.$can('read', 'mentee')
    },
    isAdmin() {
      return this.$can('read', 'admin')
    }
  },
  methods: {
    async saveChanges(ref) {
      const isValid = await this.$refs[ref].isValid()
      if(isValid) {
        let payload = this.$refs[ref].collectData()
        if(ref === 'sign-in-details') {
          this.$store.dispatch('user/changePassword', payload)
            .then(response => {
              this.showSuccess(this.$t('New password successfully saved'))
            })
            .catch(error => {
              this.showError(error)
            })
        }
        else if(ref === 'mentoring-status') {
          payload.mentor_id = this.userData.mentor.id
          this.$store.dispatch('user/mentorEditStatus', payload)
            .then(response => {
              this.showSuccess(this.$t('Data successfully saved'))
            })
            .catch(error => {
              this.showError(error)
            })
        }
        else if (this.isMentor) {
          if (ref !== 'mentoring-languages') { // DO NOT REMOVE!!!
            payload.selected_languages = this.userData.mentor.supported_languages
          }
          payload.mentor_id = this.userData.mentor.id
          this.$store.dispatch('user/mentorEdit', payload)
            .then(response => {
              this.showSuccess(this.$t('Your pending edit has been sent to WoT team!'))
            })
            .catch(error => {
              this.showError(error)
            })
        }
        else if(this.isMentee) {
          payload.mentee_id = this.userData.mentee.id
          this.$store.dispatch('user/menteeEdit', payload)
            .then(response => {
              this.showSuccess(this.$t('Data successfully saved'))
            })
            .catch(error => {
              this.showError(error)
            })
        }
      }
    },
    showSuccess(message) {
      this.$root.$bvToast.toast(message, {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError(error) {
      this.$root.$bvToast.toast(Array.isArray(error.errors) && error.errors[0][0] === 'Mentor has running mentorships or pending requests' ? this.$t('Mentor cannot switch to inactive') : this.$t('An error occured, please try again later'), {
        title: this.$t('Caution'),
        variant: 'danger',
        solid: true,
      })
    }
  }
};
</script>

<style scoped>

</style>
