<template>
  <div>
    <feather-icon :icon="icon" size="18" class="mr-50" />
    <span class="font-weight-bold">{{ text }}</span>
  </div>
</template>

<script>
export default {
  components: {

  },
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    }
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>

</style>
