<template>
  <b-card no-body>
    <b-card-body>
      <slot></slot>
    </b-card-body>
    <b-card-footer footer-class="card-footer-class" v-if="!noFooter">
      <b-button variant="primary" @click="$emit('save-changes')">
        {{ $t('Save changes') }}
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardFooter
  },
  props: {
    noFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    };
  },
};
</script>

<style scoped>
  .card-footer-class {
    border: 0;
  }
</style>
