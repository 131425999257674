<template>
  <div id="mentoring-status">
    <b-form-checkbox
      v-model="active"
      class="mb-2"
      name="check-button"
      switch
    >
      {{ statusText }}
    </b-form-checkbox>
    <b-card-text v-if="!active">
      {{ $t('Your profile is not shown in mentors list. You can change this setting whenever you want.') }}
    </b-card-text>
    <b-card-text v-else>
      {{ $t('Your profile is shown in mentors list. Whoever mentee is interested in can send you a mentorship request. You can change this setting whenever you want.') }}
    </b-card-text>
  </div>
</template>

<script>
import {
  BCardText,
  BFormCheckbox
} from 'bootstrap-vue'
import { mentorStatuses } from '@mentoring-platform/constants'

export default {
  components: {
    BCardText,
    BFormCheckbox
  },
  props: {
    mentor: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      active: false
    }
  },
  computed: {
    statusText() {
      return this.active ? this.$t('Active') : this.$t('Inactive')
    }
  },
  created() {
    if (this.mentor) {
      this.active = this.mentor.status === mentorStatuses.MENTOR_STATUS_ACTIVE
    }
  },
  methods: {
    isValid() {
      return this.active === true || this.active === false
    },
    collectData() {
      return {
        status: this.active ? mentorStatuses.MENTOR_STATUS_ACTIVE : mentorStatuses.MENTOR_STATUS_INACTIVE
      }
    }
  }
}
</script>

<style lang="scss">
#mentoring-status .custom-control-input:not(:checked) ~ .custom-control-label::before {
  background-color: red;
}
</style>
