<template>
  <div>
    <validation-observer ref="signInDetailsObserver">
      <b-row>
        <b-col md="6">
          <b-form-group>
            <label for="i-sign-in-email">
              {{ $t('SignInEmail') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="signInEmail"
              rules="required|email|min:3|max:255"
              mode="lazy"
            >
              <b-form-input
                id="i-sign-in-email"
                v-model="signInEmail"
                disabled
                type="email"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <label for="i-old-password">
              {{ $t('Old password') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="oldPassword"
              rules="required"
              mode="lazy"
            >
              <b-input-group>
                <b-form-input
                  id="i-old-password"
                  v-model="oldPassword"
                  :type="oldPasswordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="toggleOldPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group>
            <label for="i-new-password">
              {{ $t('New password') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="newPassword"
              rules="required|password"
              mode="lazy"
            >
              <b-input-group>
                <b-form-input
                  id="i-new-password"
                  v-model="newPassword"
                  :type="newPasswordFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="toggleNewPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label for="i-new-password-confirmation">
              {{ $t('New password confirmation') }} <required-field />
            </label>
            <validation-provider
              v-slot="{ errors }"
              name="newPasswordConfirmation"
              rules="required"
              mode="lazy"
            >
              <b-input-group>
                <b-form-input
                  id="i-new-password-confirmation"
                  v-model="newPasswordConfirmation"
                  :type="newPasswordConfirmationFieldType"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="toggleNewPasswordConfirmationVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'
import { required, password, email, min, max } from '@mentoring-platform-validations'
import RequiredField from '@mentoring-platform/views/components/blocks/RequiredField.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    RequiredField,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    user: {
      type: Object
    }
  },
  data() {
    return {
      signInEmail: null,
      oldPassword: null,
      oldPasswordFieldType: 'password',
      newPassword: null,
      newPasswordFieldType: 'password',
      newPasswordConfirmation: null,
      newPasswordConfirmationFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  created() {
    if(this.user) {
      this.signInEmail = this.user.email
    }
  },
  methods: {
    toggleOldPasswordVisibility() {
      this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password'
    },
    toggleNewPasswordVisibility() {
      this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
    },
    toggleNewPasswordConfirmationVisibility() {
      this.newPasswordConfirmationFieldType = this.newPasswordConfirmationFieldType === 'password' ? 'text' : 'password'
    },
    isValid() {
      return this.$refs.signInDetailsObserver.validate().then(success => success)
    },
    collectData() {
      return {
        current_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.newPasswordConfirmation
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
