<template>
  <div>
    <b-card-title>
      {{ $t('Do you want your testimonials to be visible on your public profile?') }}
    </b-card-title>
    <b-form-checkbox
      v-model="visible"
      name="check-button"
      switch
      inline
    >
      <span v-if="visible">
        {{ $t('Yes') }}
      </span>
      <span v-else>
        {{ $t('No') }}
      </span>
    </b-form-checkbox>
    <b-button variant="primary" @click="saveVisibilityChoice">
      {{ $t('Save choice') }}
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BCardTitle,
  BFormCheckbox
} from "bootstrap-vue";

export default {
  components: {
    BButton,
    BCardTitle,
    BFormCheckbox
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    saveVisibilityChoice() {
      const data = {
        testimonials_are_visible: this.visible
      }
      this.$emit('save-changes', data)
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
