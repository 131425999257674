<template>
  <div>
    <b-row>
      <b-col>
        <validation-observer ref="reason-for-mentoring-modal-observer">
          <validation-provider
            v-slot="{ errors }"
            name="reasonForMentoringRequest"
            rules="required|min:3|max:1400"
            mode="lazy"
          >
            <b-form-group>
              <label for="reason-for-mentoring-request">
                {{ $t('Do you search for mentoring on a specific subject on your job?') }}
              </label>
              <b-form-text v-if="showHint">
                {{ $t('Mentoring details hint') }}
              </b-form-text>
              <b-form-textarea
                id="reason-for-mentoring-request"
                v-model="reasonForMentoringRequest"
                rows="3"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BFormGroup,
  BFormText,
  BFormTextarea,
  BLink,
  BRow
} from "bootstrap-vue"
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormText,
    BFormTextarea,
    BLink,
    BRow,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    showHint: {
      type: Boolean,
      default: false
    },
    mentee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reasonForMentoringRequest: null
    }
  },
  created() {
    if(this.mentee) {
      this.reasonForMentoringRequest = this.mentee.reason_for_mentoring
    }
  },
  methods: {
    isValid() {
      return this.$refs['reason-for-mentoring-modal-observer'].validate().then(success => success)
    },
    collectData() {
      return {
        reason_for_mentoring: this.reasonForMentoringRequest
      }
    }
  }
};
</script>

<style scoped>

</style>
